// required modules
import Model from '@/models/Model'

/**
 * This class describes a AthLogin model.
 *
 * @class AthLogin
 */
export default class AthLoginModel extends Model {

  /**
   * Define the AthLogin resource.
   *
   * @return {String} AthLogin endpoint name.
   */
  static resource() {
    return 'login'
  }

  /**
   * AthLogin/Login body
   * 
   * @returns {AthRegisterModel} AthLogin/Login body
   */
  toLogin() {
    return this.reduce([
      'rut',
      'password',
    ])
  }
}