// required modules
import Service from '@/services/api/Service'
import AthLoginModel from '@/models/Auth/AthLogin'
// import store from '@/store'

/**
 * This class describes a AthLogin service.
 * @class AthLogin (name)
 */
export default class AthLoginService extends Service {

  /**
   * 
   */
  static interceptor(config) {
    return config
  }

  /**
   * Define the AthLogin model.
   * @return {Function} AthLogin resource.
   */
  static model() {
    return AthLoginModel
  }

  /**
   * 
   */
  static login(athLogin) {
    return athLogin.attach(athLogin.toLogin())
  }

  /**
   * 
   */
  static toLogin() {
    return this.shape({
      password: '',
      rut: '',
      remember: false,
    })
  }

}