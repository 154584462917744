// modules
import VueRecaptcha from "vue-recaptcha";

// service
import AthLoginService from "@/services/api/Auth/AthLogin";
import AthRegisterService from "@/services/api/Auth/AthRegister";
// import AthRegisterAService from "@/services/api/Auth/AthRegisterA";
import GRegionService from "@/services/api/General/GRegion";
import GProvinceService from "@/services/api/General/GProvince";
import GCommuneService from "@/services/api/General/GCommune";
import PCategoryService from "@/services/api/Provider/PCategory";
import PProviderService from "@/services/api/Provider/PProvider";
import GUserAccountService from "@/services/api/General/GUserAccount";
import PSizeService from "@/services/api/Provider/PSize";
import PSubcategoryService from "@/services/api/Provider/PSubcategory";
import RulesService from "@/services/rules";
import PCertificationService from "@/services/api/Provider/PCertification";

// sign view
export default {
  name: "v-view-sign",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      tab: null,
      acceding: false,
      busy: {
        email: false,
        registryA: false,
        registryB: false,
        registryC: false,
        rut: false,
        searching: false,
        subcategory: false,
        province: false,
        provinceC: false,
        commune: false,
        communeBranch: false,
        certifications: false,
      },
      athRegister: {},
      athRegisterA: {
        is_provider: true,
      },
      athRegisterB: {
        is_provider: true,
        legalRepresentative: {
          name: "",
          rut: "",
          email: "",
          phone: "",
        },
      },
      athRegisterC: {
        is_provider: true,
        instagram: "",
        facebook: "",
        linkedin: "",
        videoUrl: "",
      },
      logo: {
        id: "",
        image: null,
      },
      brochure: {
        name: "",
        file: null,
        g_provider_document_type_id: 1
      },
      subcategories: [],
      provinces: [],
      communes: [],
      provincesC: [],
      communesC: [],
      types: [],
      siiProvider: {
        status: 500,
      },
      dialogs: {
        success: false,
      },
      gRegions: [],
      gCommunes: [],
      gProvinces: [],
      gCommunesBranch: [],
      gProvincesC: [],
      rules: {
        email: true,
        rut: true,
        emailL: true,
        rutL: true,
      },
      pProvider: null,
      image: null,
      pCategories: [],
      pSubcategories: [],
      pSizes: [],
      pTypes: [
        { id: 1, name: "Bienes" },
        { id: 2, name: "Servicios" },
      ],
      vWeb: [
        { id: 1, name: "Si" },
        { id: 2, name: "No" },
      ],
      pCertifications: [],
      certifications: [],
      videoUrl: null,
    };
  },
  methods: {
    handleDialogClickSalir() {
      this.acceding = true;
      this.acceding = false;
      this.dialogs.success = false;
      this.$router.push("/");
    },
    async getCertifications() {
      this.busy.certifications = true;
      await PCertificationService.getAll().then((res) => {
        this.pCertifications = res.data;
      });
      this.busy.certifications = false;
    },
    async getProvider(pProviderId) {
      await PProviderService.getById(pProviderId).then((pProvider) => {
        return this.$store.dispatch("ProviderModule/setData", {
          data: pProvider,
        });
      });
    },
    async handleBusinessSizeFetch() {
      await PSizeService.getAll().then((res) => {
        this.pSizes = res;
      });
    },
    async handleBusinessTypeFetch() {
      await PCategoryService.getAll().then((res) => {
        this.pCategories = res.data;
      });
    },
    handleSubcategoryFetch() {
      return PSubcategoryService.getAllPaginateByCategoryId(
        this.athRegisterA.categoryId
      );
    },
    handleCommuneFetch() {
      return Promise.resolve({
        data: this.gCommunes,
      });
    },
    async handleDialogClick() {
      this.acceding = true;
      await AthLoginService.login(this.athRegisterA.login())
        .then(this.handleLoginSuccess)
        .catch(this.handleLoginError)
        .finally(this.handleLoginFinally);
      this.acceding = false;
    },
    handleFormError() {
      this.$toast.error(
        "El formulario de registro posee errores. Se deben resolver antes de continuar."
      );
    },
    handleFormReset() {
      this.athRegister = AthRegisterService.toRegisterReset();
      this.athRegisterA = AthRegisterService.toRegisterReset();
      this.athRegisterB = AthRegisterService.toRegisterResetB();
      this.athRegisterC = AthRegisterService.toRegisterResetC();
    },
    async handleFormSubmitA() {
      // if (this.athRegisterA["g-recaptcha-response"]) {
      this.handleFormVerifiedA();
      //   return;
      // }
      // this.$refs.recaptcha.execute();
    },
    async handleFormSubmitB() {
      // if (this.athRegisterB["g-recaptcha-response"]) {
      this.handleFormVerifiedB();
      //   return;
      // }
      // this.$refs.recaptcha.execute();
    },
    async handleFormSubmitC() {
      if (this.athRegisterA["g-recaptcha-response"]) {
      this.handleFormVerifiedC();
      return;
      }
      this.$refs.recaptcha.execute();
    },
    async handleFormVerifiedA() {
      this.busy.registryA = true;
      await this.$store.dispatch("RegisterModule/setData", {
        data: this.athRegisterA,
      });
      window.lintrk("track", { conversion_id: 15269434 });
      this.busy.registryA = false;
      this.tab = 1;
    },
    async handleFormVerifiedB() {
      this.busy.registryB = true;
      let merged = { ...this.athRegisterA, ...this.athRegisterB };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      window.lintrk("track", { conversion_id: 15269434 });
      this.busy.registryB = false;
      this.tab = 2;
    },
    async handleFormVerifiedC() {
      this.busy.registryC = true;
      // let reca = this.athRegister["g-recaptcha-response"]
      let merged = {
        ...this.athRegisterA, ...this.athRegisterB, ...this.athRegisterC,
      };
      await this.$store.dispatch("RegisterModule/setData", {
        data: merged,
      });
      this.athRegister = merged;
      await AthRegisterService.toRegister(this.athRegister)
        .then(this.handleRegisterSuccess)
        .catch(this.handleRegisterError);
      window.lintrk("track", { conversion_id: 15269434 });
      // this.dialogs.success = true;
      this.busy.registryC = false;
      // this.tab = 1;
    },
    handleLoginError(payload) {
      AthLoginService.translate(payload).then(this.$toast.error);
    },
    handleLoginFinally() {
      this.acceding = false;
      this.dialogs.success = false;
    },
    async handleLoginSuccess(payload) {
      // set auth data
      await this.$store.dispatch("AuthModule/setData", {
        data: {
          ...payload.data,
          userAccount: undefined,
        },
      });
      await this.$store.dispatch("UserModule/setData", {
        data: payload.data.userAccount,
      });
      if (payload.data.userAccount.profile.code === "provider") {
        await this.getProvider(payload.data.userAccount.profile.relatedId);
      }
      await PProviderService.subcategories(this.pProvider);
      if (payload.data.userAccount.profile.code === "provider") {
        this.$router.push("/perfil/proveedor");
      } else {
        this.$router.push("/");
      }
    },
    async handleProvinceFetch(gRegionId) {
      this.gProvinces = await GProvinceService.getAllPaginateByRegionId(
        gRegionId
      );
    },
    handleRecaptchaVerify(res) {
      this.athRegisterA["g-recaptcha-response"] = res;
      this.handleFormVerifiedA();
      this.handleFormVerifiedB();
      this.handleFormVerifiedC();
    },
    async handleRegionFetch() {
      this.gRegions = await GRegionService.getAllWithCommune();
    },
    handleRegisterError(payload) {
      this.$refs.recaptcha.execute();
      AthRegisterService.translate(payload).then(this.$toast.error);
    },
    async handleRegisterSuccess(res) {
      this.pProvider = PProviderService.shape({
        id: res.data.providerId,
      });
      // let docu= this.athRegisterC.brochure
      // {name: "BROCHURE", document_type:"application/pdf", file: docu }
      this.brochure.name = "BROCHURE"
      if(this.brochure.file != null){
        await PProviderService.document(this.pProvider, this.brochure );
      }
      if(this.logo.image != null){
        this.pProvider.image = this.logo.image
        await PProviderService.logo(this.pProvider);
      }
      this.pProvider.subcategories = this.athRegisterB.subcategories.map((id) => ({ id }));
      this.dialogs.success = true;
    },
    handleRepeatPasswordRule() {
      return (
        this.athRegisterA.has_equal_passwords ||
        "Las contraseñas deben ser las mismas."
      );
    },
    handleRutBlur() {
      this.handleRutVerify();
      // if (this.athRegisterA.is_provider) {
      //   this.handleRutSearch();
      // }
    },
    // async handleRutSearch() {
    //   this.busy.searching = true;
    //   if (
    //     !this.siiProvider.data ||
    //     this.siiProvider.data.rut !== this.athRegisterA.pRut
    //   ) {
    //     this.siiProvider = await PProviderService.search({
    //       rut: this.athRegisterA.pRut,
    //     }).catch((e) => e.response);
    //   }
    //   this.busy.searching = false;
    // },
    async handleRutVerify() {
      this.busy.rut = true;
      await GUserAccountService.verify({ rut: this.athRegisterA.rut })
        .then((res) => {
          this.rules.rut = !res.data.isRegistered;
        })
        .catch(console.log);
      this.busy.rut = false;
    },
    handleRutRule() {
      return this.rules.rut || "Rut ya registrado";
    },
    handleRutLRule() {
      return this.rules.rutL || "Rut ya registrado";
    },
    async handleEmailBlur(event) {
      this.busy.email = true;
      await GUserAccountService.verify({ email: event.target.value })
        .then((res) => {
          this.rules.email = !res.data.isRegistered;
        })
        .catch(console.log);
      this.busy.email = false;
    },
    handleEmailRule() {
      return this.rules.email || "Email ya registrado";
    },
    handleSIIProviderClick() {
      if (this.siiProvider.status === 200) {
        this.$confirm(
          "Se encontraron datos del proveedor en el SII. ¿para cargar los datos obtenidos?",
          "confirmar",
          () => {
            this.handleConfirmSIIProvider();
            this.$toast.getCmp().close();
          }
        );
      }
    },
    handleConfirmSIIProvider() {
      this.athRegisterA.businessName = this.siiProvider.data.businessName;
      this.athRegisterA.email = this.siiProvider.data.email;
      this.athRegisterA.phone = this.siiProvider.data.phone;
      this.athRegisterA.address = this.siiProvider.data.address;
      this.$toast.success("Datos cargados.");
    },
  },
  created() {
    this.handleFormReset();
    this.handleRegionFetch();
    this.handleBusinessTypeFetch();
    this.getCertifications();
    this.handleBusinessSizeFetch();
    // this.getEconomicActivities();
  },
  props: {
    fileRules: {
      type: Array,
      default: () => [RulesService.required],
    },
    videoRules: {
      type: Array,
      default: () => [
        // RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
    imageRules: {
      type: Array,
      default: () => [],
    },
    addressRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(255),
        RulesService.notEmpty,
      ],
    },
    businessNameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(180),
        RulesService.notEmpty,
      ],
    },
    businessSizeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
        RulesService.notEmpty,
      ],
    },
    vWebRules: {
      type: Array,
      default: () => [RulesService.required, RulesService.select],
    },
    businessTypeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
        RulesService.notEmpty,
      ],
    },
    communeRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
        RulesService.notEmpty,
      ],
    },
    communeRulesC: {
      type: Array,
      default: () => [RulesService.select],
    },
    descriptionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(1000),
        RulesService.notEmpty,
      ],
    },
    descriptionExpRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.max(2000),
        RulesService.notEmpty,
      ],
    },
    emailRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.email,
        RulesService.max(40),
        RulesService.notEmpty,
      ],
    },
    nameRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(8),
        RulesService.max(255),
        RulesService.notEmpty,
      ],
    },
    passwordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
        RulesService.notEmpty,
      ],
    },
    phoneRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.length(9),
        RulesService.numeric,
        RulesService.notEmpty,
      ],
    },
    provinceRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
        RulesService.notEmpty,
      ],
    },
    provinceRulesC: {
      type: Array,
      default: () => [RulesService.select],
    },
    reCaptchaSiteKey: {
      type: String,
      default: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
    },
    regionRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.select,
        RulesService.notEmpty,
      ],
    },
    repeatPasswordRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.min(6),
        RulesService.max(20),
        RulesService.notEmpty,
      ],
    },
    rutRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.rut,
        RulesService.max(12),
        RulesService.notEmpty,
      ],
    },
    subcategoryRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.notEmpty,
        // RulesService.rut,
        // RulesService.max(12),
      ],
    },
    webUrlRules: {
      type: Array,
      default: () => [
        // RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  },
  watch: {
    "athRegisterA.rut"() {
      this.rules.rut = true;
    },
    "athRegisterA.email"() {
      this.rules.email = true;
    },
    async "athRegisterB.categoryId"(categoryId) {
      this.busy.subcategory = true;
      PSubcategoryService.getAllPaginateByCategoryId(categoryId).then((res) => {
        this.subcategories = [];
        this.pSubcategories = res.data;
      });
      this.busy.subcategory = false;
    },
    async "athRegisterA.regionId"(regionId) {
      if (regionId) {
        this.busy.province = true;
        GProvinceService.getAllPaginateByRegionId(regionId).then((res) => {
          this.provinces = [];
          this.gProvinces = res.data;
        });
        if (regionId != 5) {
          this.busy.provinceC = true;
          regionId = 5;
          GProvinceService.getAllPaginateByRegionId(regionId).then((res) => {
            this.provincesC = [];
            this.gProvincesC = res.data;
          });
          this.busy.provinceC = false;
        }
        this.busy.province = false;
      }
    },
    async "athRegisterA.provinceId"(provinceId) {
      if (provinceId) {
        this.busy.commune = true;
        GCommuneService.getAllPaginateByProvinceId(provinceId).then((res) => {
          this.communes = [];
          this.gCommunes = res.data;
        });
        this.busy.commune = false;
      }
    },
    async "athRegisterA.provinceIdC"(provinceId) {
      console.log("asd");
      if (provinceId) {
        this.busy.communeBranch = true;
        GCommuneService.getAllPaginateByProvinceId(provinceId).then((res) => {
          this.communesC = [];
          this.gCommunesBranch = res.data;
        });
        this.busy.communeBranch = false;
      }
    },
    gProvinces() {
      this.athRegisterA.provinceId = null;
      this.athRegisterA.communeId = null;
      this.getRef("provinceId").refresh();
    },
    gCommunes() {
      this.athRegisterA.communeId = null;
      this.getRef("communeId").refresh();
    },
    gProvinces2() {
      this.athRegisterA.provinceIdC = null;
      this.athRegisterA.communeIdBranch = null;
      this.getRef("provinceIdC").refresh();
    },
    gCommunes2() {
      this.athRegisterA.communeIdBranch = null;
      this.getRef("communeIdBranch").refresh();
    },
  },
};
